import { registerApp } from '../register-app/index'

import { showWhenPrefix } from './../root-methods.util';

export default function appClientPortal(environment) {
    console.log('hostApp: loading as client user. app-client-portal.js loaded.');

    const apps = [
        { name: 'senta-react-client-portal', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenPrefix(['/']), options: { component: 'client-portal' } }
    ];

    apps.forEach(({ name, pkg, routeHandler, options = {}, useLoad = true }) => registerApp(name, pkg, routeHandler, environment, options, useLoad));
}
