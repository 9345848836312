import * as singleSpa from 'single-spa';
import eventBus from '../event-bus';
import features from '../features';

import { load } from '../root-methods.util';

/**
 * Registers a single-spa application.
 *
 * @param {string} name - The name of the application.
 * @param {string} pkg - The package or module to load.
 * @param {Function} routeHandler - The activity function to determine when the app should be active.
 * @param {Object} environment - The environment configuration.
 * @param {Object} options - Additional custom props to pass to the app.
 * @param {boolean} useLoad - Whether to use the `load` function or `System.import`.
 */
export function registerApp(
  name,
  pkg,
  routeHandler,
  environment,
  options = {},
  useLoad = true,
) {
  const loader = useLoad
    ? () => load(pkg, false)
    : () => System.import(pkg).then((m) => m.default || m);

  singleSpa.registerApplication(name, loader, routeHandler, {
    environment,
    eventBus,
    features,
    ...options
  });
}