import { registerApp } from '../register-app/index'

// root application utils
import {
  showWhenAnyOf,
  showWhenPrefix,
  showExcept,
} from './../root-methods.util';

/**
 * The Elements hostapp has a few things that apply for both the practice side and the client side (client portal).
 */

export default function appCommon(environment) {
  console.log('hostApp: loading packages required for all user types. app-common.js loaded.');

  const apps = [
    { name: 'login', pkg: '@iris/elements2-authentication-signin-component-v01-pkg', routeHandler: showWhenPrefix(['/login', '/logout', '/logincallback', '/session-signout', '/missing-profile']) },
    { name: 'signup', pkg: '@iris/elements2-authentication-signup-component-v01-pkg', routeHandler: showWhenAnyOf(['/signup', '/signup/']), useLoad: false },
    { name: 'authentication-session-manager', pkg: '@iris/elements2-authentication-session-manager-component-pkg', routeHandler: showExcept(['/login', '/signup', '/logout']), useLoad: false },
    { name: 'global-events-manager', pkg: '@iris/elements2-global-events-manager-component-pkg', routeHandler: showExcept(['/login', '/signup', '/logout']), useLoad: false },
    { name: 'platform-ui-logging', pkg: '@iris/platform-ui-logging-component-pkg', routeHandler: showExcept(['/login', '/signup', '/logout']), useLoad: false }
  ];

  apps.forEach(({ name, pkg, routeHandler, options = {}, useLoad = true }) => registerApp(name, pkg, routeHandler, environment, options, useLoad));
}
