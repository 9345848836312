import './../styles.scss';

import { registerApp } from '../register-app/index'

import {
  showWhenPrefix,
  showExcept,
  showWhenAnyOfAndNotTaxfiler,
  showWhenPrefixWithParameters,
  showWhenClientViewRoute,
  showWhenSentaRoute,
  showWhenTaxfilerRoute,
  showWhenAnyOfOrHashMatch,
  showOnlyExactMatch,
} from './../root-methods.util';

export default function appMain(environment) {
  console.log('hostApp: loading as practice user. app-main.js loaded.');

  const isProduction = /true/g.test(environment.production);
  const isTesting = /true/g.test(environment.testing);

  if (isProduction && !isTesting) {
    require('./../feature-toggle.scss');
  }

  const apps = [
    { name: 'features', pkg: '@iris/elements2-features-component-v01-pkg', routeHandler: showExcept(['/login', '/signup', '/logout', '/missing-profile']) },
    { name: 'header', pkg: '@iris/elements2-layout-header-component-v01-pkg', routeHandler: showExcept(['/login', '/signup', '/logout', '/missing-profile']) },
    { name: 'footer', pkg: '@iris/elements2-layout-footer-component-v01-pkg', routeHandler: showExcept(['/login']), useLoad: false },
    { name: 'dashboard', pkg: '@iris/elements2-dashboard-component-v01-pkg', routeHandler: showWhenAnyOfAndNotTaxfiler(['/', '/home']) },
    { name: 'kyc', pkg: '@iris/elements2-kyc-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/kyc']), useLoad: false },
    { name: 'templates', pkg: '@iris/elements2-templates-component-v01-pkg', routeHandler: showWhenPrefix(['/templates/aml/kyc']), useLoad: false },
    { name: 'administration', pkg: '@iris/elements2-admin-component-v01-pkg', routeHandler: showWhenPrefix(['/administration']), useLoad: false },
    { name: 'riskassessment', pkg: '@iris/elements2-riskassessment-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/risk-assessment']), useLoad: false },
    { name: 'suspiciousactivity', pkg: '@iris/elements2-suspiciousactivity-component-v01-pkg', routeHandler: showWhenPrefix(['/suspicious-activity']), useLoad: false },
    { name: 'practicecompliance', pkg: '@iris/elements2-practicecompliance-component-v01-pkg', routeHandler: showWhenPrefix(['/aml/practice-compliance', '/domain-link/risk/firmassessment']) },
    { name: 'client-account', pkg: '@iris/tax-client-account-component-v01-pkg', routeHandler: showWhenPrefix(['/client-account']) },
    { name: 'capital-assets', pkg: '@iris/capitalassets-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/capital-assets/client/:id/']) },
    { name: 'capital-allowances', pkg: '@iris/capitalassets-allowances-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/capital-allowances']) },
    { name: 'taxfiler', pkg: '@iris/elements2-tax-filer-holder-component-v01-pkg', routeHandler: showWhenTaxfilerRoute() },
    { name: 'corporation-losses', pkg: '@iris/tax-corporation-losses-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/corporation-tax/losses/client', '/corporation-tax/losses/client/:clientId', '/corporation-tax/losses/client/:clientId/tax-return/:taxReturnId']) },
    { name: 'corporation-groupsandconsortiums', pkg: '@iris/tax-corporation-groupsandconsortiums-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/corporation-tax/groupsandconsortiums/client', '/corporation-tax/groupsandconsortiums/client/:clientId', '/corporation-tax/groupsandconsortiums/client/:clientId/tax-return/:taxReturnId']) },
    { name: 'corporation-loans-to-participators', pkg: '@iris/tax-corporation-loans-to-participators-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/corporation-tax/loans/client', '/corporation-tax/loans/client/:clientId', '/corporation-tax/loans/client/:clientId/tax-return/:taxReturnId']) },
    { name: 'corporation-tax-credits', pkg: '@iris/tax-corporation-tax-credits-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/corporation-tax/tax-credits/client', '/corporation-tax/tax-credits/client/:clientId', '/corporation-tax/tax-credits/client/:clientId/tax-return/:taxReturnId']) },
    { name: 'corporation-supplementary', pkg: '@iris/tax-corporation-supplementary-component-v01-pkg', routeHandler: showWhenPrefix(['/corporation-supplementary', '/corporation-tonnage', '/corporation-ring-fenced', '/corporation-restitution-tax']) },
    { name: 'authorise-agent648', pkg: '@iris/tax-client-authorise-agent-component-v01-pkg', routeHandler: showWhenPrefix(['/authorise-agent']) },
    { name: 'practicedetails', pkg: '@iris/practice-detail-management-component-v01-pkg', routeHandler: showWhenPrefix(['/practice-details']) },
    { name: 'self-assessment', pkg: '@iris/tax-client-return-component-v01-pkg', routeHandler: showWhenPrefix(['/self-assessment/view-all', '/corporate/view-all']) },
    { name: 'vat-view-all', pkg: '@iris/tax-client-vat-component-v01-pkg', routeHandler: showWhenPrefix(['/vat/view-all']) },
    { name: 'mtd-view-all', pkg: '@iris/tax-client-mtd-component-v01-pkg', routeHandler: showWhenPrefix(['/mtd/obligations/view-all']) },
    { name: 'partnership-shares', pkg: '@iris/tax-partnership-shares-component-v01-pkg', routeHandler: showWhenPrefix(['/partnership-shares']) },
    { name: 'communications-websockets-app', pkg: '@iris/communications-websockets-component-v01-pkg', routeHandler: showExcept(['/login', '/signup', '/signup/', '/logout', '/missing-profile']) },
    { name: 'communications-notifications-app', pkg: '@iris/communications-notifications-component-v04-pkg', routeHandler: isTesting ? showExcept(['/login', '/signup', '/logout', '/missing-profile', '/signup/']) : showExcept(['/login', '/logout', '/missing-profile']) },
    { name: 'migration-tool', pkg: '@iris/elements2-migration-tool-component-v01-pkg', routeHandler: showWhenPrefix(['/data-migration-service']) },
    { name: 'clients-prospects', pkg: '@iris/elements2-clients-prospects-component-v02-pkg', routeHandler: showWhenPrefix(['/client-management', '/client-management/', '/domain-link/risk/assessment', '/domain-link/ias']) },
    { name: 'import-clients', pkg: '@iris/elements2-clients-import-component-v01-pkg', routeHandler: showWhenAnyOfOrHashMatch(['/client-management/#import-clients', '/client-management/v2/#import-clients']) },
    { name: '404', pkg: '@iris/elements-404-component-v01-pkg', routeHandler: showWhenPrefix(['/404']) },
    { name: 'user-management', pkg: '@iris/platform-user-management-component-pkg', routeHandler: showWhenPrefix(['/user-management', '/user/profile']) },
    { name: 'company-formation', pkg: '@iris/elements-company-formation-component-v00-pkg', routeHandler: showWhenPrefix(['/company-formations']) },
    { name: 'global-settings', pkg: '@iris/elements2-globalsettings-dashboard-component-v01-pkg', routeHandler: showWhenPrefix(['/global-settings']) },
    { name: 'external', pkg: '@iris/elements2-taxfiler-layout-component-v01-pkg', routeHandler: showWhenPrefix(['/external']) },
    { name: 'accounts-production', pkg: '@iris/elements-accounts-production-component-v00-pkg', routeHandler: showWhenPrefix(['/accounts-production']) },
    { name: 'accounts-builder', pkg: '@iris/elements-accounts-builder-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/accounts-builder']) },
    { name: 'account-period-data', pkg: '@iris/elements-account-period-data-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/source-data', '/accounts-production/periods/:clientId/:periodId/adjustments', '/accounts-production/periods/:clientId/:periodId/import-wizard/:importId/import', '/accounts-production/periods/source-data/bookkeeping/callback']) },
    { name: 'accounts-submission', pkg: '@iris/elements-accounts-submission-component-pkg', routeHandler: showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/approval', '/accounts-production/periods/:clientId/:periodId/submission']) },
    { name: 'reporting', pkg: '@iris/elements-reporting-component-v01-pkg', routeHandler: showWhenPrefix(['/reporting']) },
    { name: 'senta-angularjs', pkg: '@iris/elements2-senta-angular-client', routeHandler: showWhenSentaRoute(), options: { domElementId: 'senta-angular-holder' }, useLoad: false },
    { name: 'senta-angularjs-client-view', pkg: '@iris/elements2-senta-angular-client', routeHandler: showWhenClientViewRoute(), options: { domElementId: 'senta-angular-tab-holder' }, useLoad: false },
    { name: 'senta-react-client-tabs', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenClientViewRoute(), options: { component: "client-view" } },
    { name: 'senta-react-external-download', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenPrefix(['/download', '/att']), options: { component: "download" } },
    { name: 'senta-react-import-export', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenPrefix(['/tools/import-export']), options: { component: "import-export" } },
    { name: 'senta-react-integrations-auth', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenPrefix(['/pm/integrations']), options: { component: "integrations-auth" } },
    { name: 'ipm-dashboard', pkg: '@iris/ipm-dashboard-v01-pkg', routeHandler: showWhenPrefix(['/proposal-management/dashboard']) },
    { name: 'ipm-config', pkg: '@iris/ipm-config-v01-pkg', routeHandler: showWhenPrefix(['/proposal-management/config']) },
    { name: 'ipm-proposal', pkg: '@iris/ipm-proposal-v01-pkg', routeHandler: showWhenPrefix(['/proposal-management/proposals']) },
    { name: 'financial-performance', pkg: '@iris/elements2-reporting-financial-performance-v01-pkg', routeHandler: showWhenPrefix(['/financial-performance']) },
    { name: 'cosec-main', pkg: '@iris/cosec-main-v01-pkg', routeHandler: showWhenPrefix(['/company-secretarial']) },
    { name: 'xbrl', pkg: '@iris/xbrl-mapping-components-pkg', routeHandler: showWhenPrefix(['/xbrl']) },
    { name: 'credit-score-results', pkg: '@iris/elements2-capitalise-credit-score-results-pkg', routeHandler: showWhenPrefix(['/credit-score']), useLoad: false },
    { name: 'import-wizard', pkg: '@iris/integrations-clients-import-wizard-component-pkg', routeHandler: showWhenPrefix(['/import-wizard/clients']) },
    { name: 'profit-share', pkg: '@iris/elements2-accountsproduction-profit-share-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/profit-share']) },
    { name: 'scenario-planning', pkg: '@iris/tax-planning-scenario-component-v01-pkg', routeHandler: showWhenPrefix(['/scenario-planning']) },
    { name: 'accounts', pkg: '@iris/elements2-taxfiler-accounts-pkg', routeHandler: showOnlyExactMatch(['/accounts']) },
    { name: 'tax-accounts-due-dates', pkg: '@iris/elements2-tax-tax-accounts-due-dates-component-v01-pkg', routeHandler: showOnlyExactMatch(['/tax-accounts-due-dates/view-all']) },
    { name: 'ifm', pkg: '@iris/elements2-integrations-thanos-v01-pkg', routeHandler: showWhenPrefix(['/ifm', '/time-and-expenses', '/billing', '/accounts-receivable']) },
    { name: 'tax-checklist', pkg: '@iris/elements2-tax-checklist-component-v01-pkg', routeHandler: showOnlyExactMatch(['/external/global-settings/e-checklists']) },
    { name: 'account-schedule', pkg: '@iris/elements2-tax-corporation-accountschedule-pkg', routeHandler: showOnlyExactMatch(['/account-schedule']) },
    { name: 'iframe-loader', pkg: '@iris/elements2-iframeloader-v01-pkg', routeHandler: showWhenPrefix(['/iframe-loader']) },
    { name: 'senta-react-send-bulk-email', pkg: '@iris/elements2-senta-react-client', routeHandler: showWhenPrefix(["/e/a"]), options: { component: "send-bulk-email" } },
    { name: 'cost-centres', pkg: '@iris/elements2-accountsproduction-costcentre-component-pkg', routeHandler: showWhenPrefixWithParameters(['/accounts-production/periods/:clientId/:periodId/cost-centres']) },
    { name: 'test-harness', pkg: '@iris/hmrc-test-harness-component-pkg', routeHandler: showWhenPrefixWithParameters(['/hmrc-test-harness']) },
    { name: 'mtd-obligations-self-employment', pkg: '@iris/elements2-mtd-self-employment-component-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/obligations/self-employment/:id/summary']) },
    { name: 'mtd-obligations-uk-property', pkg: '@iris/elements2-mtd-hmrc-period-data-retrieval-screen-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/obligations/uk-property/:obligationId/']) },
    { name: 'mtd-obligations-foreign-property', pkg: '@iris/elements2-mtd-foreign-property-data-retrieval-screen-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/obligations/foreign-property/:obligationId/']) },
    { name: 'mtd-individual-calculations', pkg: '@iris/elements2-mtd-individual-calculations-component-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/individuals/calculations/:nino/']) },
    { name: 'mtd-obligations-uk-property-annual', pkg: '@iris/elements2-mtd-uk-property-annual-submission-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/obligations/uk-property-annual/:obligationId/']) },
    { name: 'mtd-obligations-foreign-property-annual', pkg: '@iris/elements2-mtd-foreign-property-annual-submission-pkg', routeHandler: showWhenPrefixWithParameters(['/mtd/obligations/foreign-property-annual/:obligationId/']) },
    { name: 'taxreturndata-corporation-restitution', pkg: '@iris/taxreturndata-corporation-restitution-component-v01-pkg', routeHandler: showWhenPrefixWithParameters(['/corporation-tax/restitution/client', '/corporation-tax/restitution/client/:clientId/tax-return/:taxReturnId']) }
  ];

  apps.forEach(({ name, pkg, routeHandler, options = {}, useLoad = true }) => registerApp(name, pkg, routeHandler, environment, options, useLoad));
}
